<template>
  <Card class="max-w-prose self-center">
    <CardHeader>
      <span data-cy="welcome">
        {{ $t("index.welcome_title") }}
      </span>
      <template #icon>
        <HazcheckIcon class="size-6 text-neutral-800 dark:text-neutral-100" />
      </template>
    </CardHeader>

    <CardSection>
      <p class="m-0! whitespace">
        {{
          store.showWsc
            ? $t("index.welcome_text_wsc")
            : $t("index.welcome_text")
        }}
      </p>
    </CardSection>
  </Card>
  <Space />
  <div
    class="flex justify-center flex-nowrap! gap-4 overflow-hidden max-h-[200px]"
  >
    <Logo v-if="store.showWsc" width="115px" variant="wsc" />
    <Logo
      width="160px"
      :style="[
        store.showWsc ? 'margin-bottom: -1px; ' : '',
        'align-self: center',
      ]"
      variant="ncb-solac"
    />
  </div>
</template>

<script setup lang="ts">
import { CardHeader, Logo } from "#components";
import { useUserStore } from "~/stores/userStore";

const store = useUserStore();
</script>
